.topNav {
    background: #fff;
    height: 100px;
    z-index: 6;
    padding: 0;
}

.user-name {    
    color: var(--secondary);
    font-size: 22px;
}

.user-role {
    color: var(--secondary);
    font-size: 12px;
    font-weight: lighter;
}

.gradient-bar {
    width: '100%';
    height: 15px;
    background: linear-gradient(90deg, rgba(4, 97, 145, 1) 0%, rgba(151, 195, 105, 1) 57%, rgba(4, 97,145,1)100%);
}

.user-image {
    width: 65px;
    height: 65px;
    background: #086390;
    color: #fff;
    font-size: 34px;
    font-weight: bold;
}

  
