/* Now when we use rem 1rem = 10px */
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&family=Text+Me+One&display=swap');

/* COLOR SCHEMES */
:root {
    --background: #ffffff00;

    --primary: #97c469;
    --text-primary: #97c469;
    --primary-hover: #97c46998;

    --secondary: #086390;
    --text-secondary: rgb(0, 0, 0);
    --secondary-hover: #086390af;
    --view: #a4b4d38f;
    --opposite: #ffffff;

    --danger: #b85252;
    --text-danger: royalblue;
    --danger-hover: #7e2020f1;

    --success: #c77e9f;
    --text-success: royalblue;
    --success-hover: #8ea7cef1;

    --text-light: white;
    --accent: rgba(128, 0, 128, 0.182);

    --table-head: #5a1af0c3;

    --icon: #397c37;
}

[data-theme="dark"] {
    --background: rgba(0, 0, 0, 0.897);
    --view: rgb(66, 66, 66);
    --opposite: #585858;
    --primary: rgba(0, 0, 0, 0.675);
    --primary-hover: #272727;
    --secondary: rgba(0, 0, 0, 0.675);
    --secondary-hover: #272727;
    --text-secondary: white;
    --accent: darkred;

    --table-head: rgba(0, 0, 0, 0.675);
    --icon: rgb(205, 205, 205);
}

body {
    background: var(--background);
    font-size: 16px;
    margin: 0;
    font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* .Toastify__toast-container--top-right {
    top: 70px;
} */
