.sideNav {
    background:  var(--secondary);
    position: fixed;
    width: 15vw;
    height: 100%;
    padding-top: 115px;
    left: 0;
    top: 0px;
    z-index: 5;
}

.navbar-light .navbar-nav .nav-link {
    color: var(--text-light);
    font-weight: bold;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: var(--view);
    font-weight: bold;
}

.navbar-light .navbar-nav .nav-link.active {
    color: var(--text-light);
    font-weight: bold;
}
.navbar-light .navbar-nav .nav-link.active:hover {
    color: var(--view);
    font-weight: bold;
}
