.welcomeCarousel .carousel-control-next,
.welcomeCarousel .carousel-control-prev 
/* .welcomeCarousel .carousel-indicators   */ {
    filter: invert(100%);
}

.welcomeCarousel{
    display: flex;
    flex-direction: column-reverse;
}
.welcomeCarouselItem{
    min-height: 500px;
}

.welcomeCarousel .carousel-indicators{
    margin-top: 5px;
     position: relative;
}