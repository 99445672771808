  @media only screen and (min-width: 1200px) {
    .sideCon {
      position: fixed;
      height: 100%;
      width: 15vw;
      transition: left 0.3s ease-in-out;
      /* overflow: auto; */
      border-right: 1px solid var(--con);
    }
  }
  