.app {
    min-height: 100vh;
    color: var(--text-secondary);
    background-color: var(--background);
    transition: all 0.5s;
}

.row {
    padding: 0;
    margin: 0;
}



.App-link {
    color: #61dafb;
}

.marketingInfo {
    background: #fff;
    border-radius: 6px;
}

.loginForm {
    background: #fff;
    border-radius: 6px;
    margin-left: 3%;
    margin-right: 2%;
    height: 100%;
}

.loginTitle {
    color: #000;
}

.hoverMe {
    cursor: pointer;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}

.form-floating label {
    color: black;
}

.text-primary {
    color: var(--text-primary) !important;
}

/* CONTAINERS*/
.primaryShadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background-color: var(--view);
    border-radius: 5px;
}

/* Buttons */
.btn-primary {
    background: var(--primary);
    border-color: var(--primary);
}
.btn-primary:hover {
    background: var(--primary-hover);
    border-color: var(--primary-hover);
}

.btn-primary:focus {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    box-shadow: 0 0 0 0.25rem rgb(var(--primary));
}
.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
}

.btn-danger {
    background: var(--danger);
    border-color: var(--danger);
}
.btn-danger:hover {
    background: var(--danger-hover);
    border-color: var(--danger-hover);
}

.btn-secondary {
    background: var(--secondary);
    border-color: var(--secondary);
}
.btn-secondary:hover {
    background: var(--secondary-hover);
    border-color: var(--secondary-hover);
}

.secondary {
    color: var(--secondary);
}

.btn-success {
    background: var(--success);
    border-color: var(--success);
}
.btn-success:hover {
    background: var(--success-hover);
    border-color: var(--success-hover);
}

/* Tables */
.tableHead {
    background: var(--table-head);
    color: var(--text-light);
}
.tableRow {
    background: var(--opposite);
    color: var(--text-secondary);
}

.tableCheckMark {
    color: #22ac1d;
}

.table {
    color: var(--text-secondary);
}

.bold {
    font-weight: bold;
}

.dataSetTextB {
    margin-top: -17px;
    margin-left: -3px;
    margin-bottom: -20px;
}

.dataSetTextC {
    margin-top: -45px;
    margin-left: -50px;
    margin-bottom: -45px;
}

.dataSetTextD {
    margin-top: -5px;
    margin-left: -25px;
    margin-bottom: -30px;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.pop-up {
    margin-top: 10%;
}

.white {
    color: #fff;
    background-color: #fff;
}

.closeBtn {
    color: red;
    font-weight: bold;
    font-size:x-large;
    margin-top: -15px;
    margin-right: -10px;
}

.list-item { 
    background-color: rgb(221, 221, 221);
    border-radius: 6px;
}

.delete {
    background-color: red;
}

.delete:hover {
    background-color: rgba(255, 0, 0, 0.589);
}

.sticky {
    position: sticky;
    top: 0px;
}

.bringFront {
    position: absolute;
    z-index: 4;
}

.inputBorder {
    border: 1px solid #d0d0d0;
    border-radius: 3px;
    padding: 6px;
}

.inputBorder:focus {
    outline: none; /* Goodbye browser default outline... */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.404); 
}

.dropdown {
    position: absolute;
    top: 100;
    left: 0;
    z-index: 999;
    background: white;
    width: 100%;
    border: 1px solid #d0d0d0;
    border-radius: 3px;
    margin-left: 1px;
    margin-top: 2px;
}

.option:hover {
    background-color: rgb(216, 216, 216);
    border-radius: 3px;
}