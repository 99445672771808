.landingPage{
    min-height: 100vh;
}

.homePage {
    margin: 0px;
    max-width: 100%;
    height: calc(100vh - 66px);
    background: url(../../Components/Imgs/LoginBackground.jpg) no-repeat center;
    background-size: cover;
  }

  .login {
    margin-top: -100px;
  }