.fact-reference{
    font-size: 12px;
}

.fact1{
    font-size: 28px;
}
.fact1Span{
    font-size: 38px;
    font-weight: bold;
    color: var(--primary);
}

.fact2{
    font-size: 30px;
}
.fact2Span{
    font-size: 41px;
    font-weight: bold;
    color: var(--primary);
}

.fact3{
    font-size: 37.6px;
}
.fact3Span{
    font-size: 48px;
    font-weight: bold;
    color: var(--primary);
}

.fact4{
    font-size: 35px;
}
.fact4Span{
    font-size: 45px;
    font-weight: bold;
    color: var(--primary);
}

.fact5{
    font-size: 35px;
}
.fact5Span{
    font-size: 45px;
    font-weight: bold;
    color: var(--primary);
}

.fact6{
    font-size: 30px;
}
.fact6Span{
    font-size: 40px;
    font-weight: bold;
    color: var(--primary);
}

.fact7{
    font-size: 35px;
}
.fact7Span{
    font-size: 45px;
    font-weight: bold;
    color: var(--primary);
}

.fact8{
    font-size: 25px;
}
.fact8Span{
    font-size: 35px;
    font-weight: bold;
    color: var(--primary);
}

.fact9{
    font-size: 30px;
}
.fact9Span{
    font-size: 40px;
    font-weight: bold;
    color: var(--primary);
}

.fact10{
    font-size: 30px;
}
.fact10Span{
    font-size: 40px;
    font-weight: bold;
    color: var(--primary);
}