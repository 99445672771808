.activateInnerCon{
    background: var(--secondary);
    color: white;
}
.activateMainCon{
    height: 70vh;
}

.activateMessageCon{
    border-radius: 5px;
    background: white;
    color: black;
}